// src/components/AppSection.js

import React, { useState, useEffect } from 'react';
import ArtyFooter from '../ArtyFooter/ArtyFooter';
import EmailEntryContainer from '../EmailEntryContainer/EmailEntryContainer';
import './OurGoals.css';

const AppSection = () => {


   // Function to calculate the time left until December 1st
   const calculateTimeLeft = () => {
    const difference = +new Date('December 1, 2024 00:00:00') - +new Date();
    let timeLeft = {};

    if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        };
    }

    return timeLeft;
};

// State to hold the time left
const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

// Effect to update the countdown every second
useEffect(() => {
    const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
});

// Convert timeLeft object into an array of JSX elements
const timerComponents = [];

Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
        return;
    }

    timerComponents.push(
        <span key={interval}>
            {timeLeft[interval]} {interval}{" "}
        </span>
    );
});


    return (
        <section id="goals" className="content">
    
  

            <div id="goals" className="content-wrapper">


             



                {/* <div className="invisible-text-box-png">
               <img className="goals-text" src='knittieGoals.png'/>
                </div> */}

                <div className='intro-text-box'>

                <div className='gridTitle'>Welcome</div><p>
           
                <br/><br/>
After two years of dedicated development, we are thrilled to introduce <vb>Knittie</vb>, the ultimate app for knitters who want to take their craft to the next level. At Knittie, we specialise in blending cutting-edge software, innovative design, and a deep passion for knitting. Our team of experts has worked tirelessly to bring the art of knitting into the 21st century.
<br/><br/>
With Knittie, you have the power to <vb>fully customise your knitting patterns</vb>, unleashing your creativity like never before. Our ambition is to empower the knitting (and soon crochet) community with unprecedented creative control. Your invaluable feedback has been instrumental in shaping Knittie into the game-changing tool it is today.
<br/><br/>
We are incredibly excited to continue this journey with the support of seasoned knitters. Together, we can revolutionise the knitting world, making it more enjoyable and aligned with the technology of our era. <vb>Join us</vb> at Knittie and discover a new world of possibilities in your knitting adventures!

                        </p>

             <ArtyFooter/>
             

                    {/* Live countdown to December 1st */}
                      <div className='comingSummerText'>
                        {timerComponents.length ? timerComponents : <span>Available on the App Store now!</span>}
                    </div>
                </div>

                <div className="phoneImageContainer">
                    <img className="phoneImage" src="Kphone.png" alt="Sample Image 2" />
                </div>
                

            </div>

   
        </section>
    );
};

export default AppSection;

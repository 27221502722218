import React from 'react';
import './AppSection.css';
import ArtyFooter from '../ArtyFooter/ArtyFooter';

const AppSection = () => {
    return (
        <section id="app" className="section">
            <div className="content-wrapper">
                <div className="app-section-container">
                    {/* App Store Logo */}
                    <div className="app-store-container">

                        <a href="https://apps.apple.com/gb/app/knittie/id6478239889?platform=iphone" target="_blank" rel="noopener noreferrer">
                            <img className="appStoreLogo" src="app-store-logo.png" alt="App Store Logo" />
                        </a>
                    </div>

                    {/* Text Box */}
                    <div className="text-box">
                        <h2 className="AppHeading">Download the App</h2>
                        <p>
                            Click the App Store logo to download Knittie and start exploring a world of creativity. 
                            Knittie is your ultimate companion for bringing your designs to life. Whether you're a seasoned pro or just picking up the 
                            needles, we've got you covered!
                        </p>
                        <ArtyFooter />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AppSection;

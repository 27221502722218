import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const Footer = () => {
    return (
        <section id="footer" className="section">
            <div className="content-wrapper">
                <h2 className="thanksHeading">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                </h2>
                <div className="instagramFooter">
                    <a href="https://www.instagram.com/knittieofficial/">
                        <img className="insta-logo-link" src="insta.jpeg" alt="Instagram Logo" />
                    </a>
                    <a href="https://apps.apple.com/gb/app/knittie/id6478239889?platform=iphone" target="_blank" rel="noopener noreferrer">
                            <img className="app-logo-link" src="app-store-logo.png" alt="App Store Logo" />
                        </a>
                </div>

              
            </div>
        </section>
    );
};

export default Footer;

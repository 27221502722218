import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Import Routes instead of Switch
import Header from './components/Header/Header';
import HeroSection from './components/HeroSection/HeroSection';
import OurGoals from './components/OurGoals/OurGoals';
import AboutSection from './components/AboutSection/AboutSection';
import ContactSection from './components/ContactSection/ContactSection';
import LeadGenerationSection from './components/LeadGenerationSection/LeadGenerationSection';
import MadeWithSection from './components/MadeWithSection/MadeWithSection';
import Footer from './components/Footer/Footer';
import ExamplesSection from './components/ExamplesSection/ExamplesSection';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy'; // Import PrivacyPolicy

import './App.css'; // Import your CSS
import AppSection from './components/AppSection/AppSection';

const App = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        <main>
          <Routes> {/* Use Routes instead of Switch */}
            {/* Home Page */}
            <Route path="/" element={
              <>
                <HeroSection />
             
                <OurGoals />
                <AppSection/>
                <LeadGenerationSection />
                <ExamplesSection />
                <AboutSection />
                <ContactSection />
                <MadeWithSection />
              </>
            } />

            {/* Privacy Policy Page */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
